<template>
  <div class='about'>
    <div>会社名</div>
    <p>株式会社 Nyaology</p>
    <div>法人設立日</div>
    <p>2019年06月04日</p>
    <div>現住所（2021年04月15日〜）</div>
    <p>〒104-0061 東京都中央区銀座1丁目22番11号 銀座大竹ビジデンス2F</p>
    <div>旧住所（2019年06月04日 〜 2021年04月15日）</div>
    <p>〒111-0056 東京都台東区小島2丁目20番11号LIGビル</p>
    <div>法人番号</div>
    <p>3010501044228</p>
    <div>事業所整理番号（厚生年金）</div>
    <p>25ﾆｾｽ</p>
    <div>事業所番号（厚生年金）</div>
    <p>34401</p>
    <div>事業所整理番号（健康保険）</div>
    <p>25044647</p>
  </div>
</template>

<script>
export default {
  name: 'about',
  mounted () {
    // 処理中を解除
    this.$store.commit('setProcessing', false)
  }
}
</script>

<style scoped lang='scss'>
@import '@/assets/sass/size.scss';

.about {
  padding: $padding_height $padding_width;
  margin-top: $header_height;
}
</style>
